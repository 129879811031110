import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { SocialChannelsGuard } from './social-media/guards';

const appGuards = [CookieSettingsGuard, DictionaryGuard, MenuGuard, SocialChannelsGuard];

const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.module').then(m => m.StyleGuideModule),
    canActivate: [],
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./sitemap/sitemap.module').then(m => m.SitemapModule),
    canActivate: [...appGuards],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    matcher: landingPageMatcher,
    loadChildren: () => import('./landing-pages/landing-pages.module').then(m => m.LandingPagesModule),
    canActivate: [...appGuards],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

function landingPageMatcher(url: UrlSegment[]) {
  const posParams: { [key: string]: UrlSegment } = {};
  if (url?.length === 1) {
    posParams['slug'] = new UrlSegment(url[0].path, {});
  } else if (url?.length > 1) {
    posParams['slug'] = new UrlSegment(url.join(''), {});
  } else {
    posParams['slug'] = new UrlSegment('', {});
  }

  return { consumed: url, posParams };
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
