import { Component, PLATFORM_ID, Renderer2, inject, computed, effect } from '@angular/core';

import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { filter } from 'rxjs/operators';
import { routerFade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';
import { CookieConfigService, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { getRouterState } from './store';
import { getMenuLoaded } from '@teamfoster/sdk/menu-ngrx';
import { getTheme } from './store/selectors/theme.selector';
import { toSignal } from '@angular/core/rxjs-interop';
import { getAllSocialChannels } from './social-media/store';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private store = inject(Store);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private platformId = inject(PLATFORM_ID);
  private document: any = inject(DOCUMENT);
  private cookieConfig = inject(CookieConfigService);

  routeState$ = this.store.selectSignal(getRouterState);
  menuOpen$ = this.store.selectSignal(navSelectors.getMenuOpen);
  cookiePrefs$ = this.store.selectSignal(getCookiePreferences);
  theme$ = this.store.selectSignal(getTheme);
  navTheme$ = this.store.selectSignal(navSelectors.getNavTheme);
  dictionaryLoaded$ = this.store.selectSignal(getDictionaryLoaded);
  menuLoaded$ = this.store.selectSignal(getMenuLoaded);
  socialChannels$ = this.store.selectSignal(getAllSocialChannels);
  hideFooterPayoff$ = this.store.selectSignal(navSelectors.getHideFooterPayoff);

  isClient$ = computed(() => isPlatformBrowser(this.platformId));

  private routerEvents$ = toSignal(this.router.events.pipe(filter(e => [NavigationStart, NavigationEnd].some(b => e instanceof b))));
  loading$ = computed(() => !(this.routerEvents$() instanceof NavigationEnd));
  loaded$ = computed(() => this.dictionaryLoaded$() && this.menuLoaded$());

  domainName = this.document.location.hostname;
  testGridEnabled = false;
  routerAnimationState = '';

  menuOpenEffect$ = effect(() => this.updateRoot(this.menuOpen$()));
  routerEffect$ = effect(() => {
    const event = this.routerEvents$();
    const state = this.routeState$();

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (event instanceof NavigationStart && event.navigationTrigger === 'imperative' && !state?.state?.queryParams) {
      window.scrollTo(0, 0);
    }

    if (event instanceof NavigationEnd && this.cookiePrefs$()?.analytical) {
      if ((<any>window).gtag) {
        (<any>window).gtag('config', this.cookieConfig.analyticsCode, { page_path: event.urlAfterRedirects, anonymize_ip: true });
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('body').classList.add('set--in');
      this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      this.listenToShortcuts();
    }
  });

  private previousPath = '';

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  listenToShortcuts() {
    // Shortcuts
    const shortcutKeys = ['Escape'];
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(filter((event: KeyboardEvent) => shortcutKeys.includes(event.code)))
      .subscribe((a: any) => {
        if (a.code === 'Escape') {
          this.store.dispatch(navActions.CloseMenu());
        }
      });
  }

  private updateRoot(menuActive: boolean) {
    //menuActive
    //  ? this.renderer.addClass(this.document.body, 'main-nav--active')
    //  : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }

  //ngAfterViewInit(): void {
  //  console.log('hoi?');
  //  if (isPlatformBrowser(this.platformId)) {
  //    console.log('browser');
  //    //this.router.events.pipe(filter(e => [NavigationEnd].some(b => e instanceof b))).subscribe(a => {
  //    // create the scrollSmoother before your scrollTriggers
  //    ScrollSmoother.create({
  //      smooth: 0.3, // how long (in seconds) it takes to "catch up" to the native scroll position
  //      effects: true, // looks for data-speed and data-lag attributes on elements
  //      smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
  //    });

  //    //initGSAPSplit();

  //    //});
  //  }
  //}
}
