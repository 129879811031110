import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // Implement your logic here to determine if the route should be detached.
    return false; // Change to true if you want to detach the component.
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    // Implement your logic here to store the route handle if shouldDetach returns true.
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // Implement your logic here to determine if the route should be attached.
    return false; // Change to true if you want to attach the previously detached component.
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    // Implement your logic here to retrieve the route handle if shouldAttach returns true.
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Dont reuse route because i want pretty animations every time :)
    //console.log(future, future.routeConfig, curr.routeConfig);
    return false;
    //return future.routeConfig === curr.routeConfig;
  }
}
