import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromNav from '../reducers/nav.reducer';

export const getNavState = createSelector(fromFeature.getNavState, (state: fromNav.NavState) => state);

export const getMenuOpen = createSelector(getNavState, (state: fromNav.NavState) => state.menuOpen);
export const getNavTheme = createSelector(getNavState, (state: fromNav.NavState) => state.menuTheme);
export const getHideFooterPayoff = createSelector(getNavState, (state: fromNav.NavState) => state.hideFooterPayoff);
