<div class="app" [attr.data-bs-theme]="theme$() || ''">
  <div class="app__inner" [class.anim--in]="loading$()">
    @if (loaded$()) {
    <app-topbar
      (toggleOverlay)="toggleMenu()"
      (closeOverlay)="closeMenu()"
      (openOverlay)="openMenu()"
      [menuActive]="menuOpen$()"
      [menu]="('main-menu' | fromMenu)?.error ? [] : ('main-menu' | fromMenu)"
      [quickMenu]="('topbar-menu' | fromMenu)?.error ? [] : ('topbar-menu' | fromMenu)"
      [socials]="socialChannels$()"
      [theme]="navTheme$()"
    ></app-topbar>
    }

    <div id="smooth-wrapper" [class.main-nav--active]="menuOpen$()">
      <div id="smooth-content">
        <div class="app__router" [@routerFade]="prepareRoute(outlet)">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>

        @if (loaded$()) {
        <app-footer
          [menu]="('footer-menu' | fromMenu)?.error ? [] : ('footer-menu' | fromMenu)"
          [socials]="socialChannels$()"
          [highlightMenu]="('footer-highlight-menu' | fromMenu)?.error ? [] : ('footer-highlight-menu' | fromMenu)"
          [domain]="domainName"
          [hidePayoff]="hideFooterPayoff$()"
        ></app-footer>
        }
      </div>
    </div>

    @if (loaded$() && isClient$()) {
    <fstr-cookie-notice
      [title]="'cookie-titel' | fromDictionary"
      [text]="'cookie-tekst' | fromDictionary"
      [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
      [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
    ></fstr-cookie-notice>
    }

    <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
  </div>
</div>

@if (testGridEnabled) {
<app-grid-overlay></app-grid-overlay>
}
